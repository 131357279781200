import React, { useContext } from 'react';
import StoreContext from '../../context/store-context.js';
import LineItem from '../../components/shop/line-item.js';

const Cart = () => {
  const {
    store: { checkout }
  } = useContext(StoreContext);

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  const lineItems = checkout.lineItems.map(lineItem => {
    return <LineItem key={lineItem.id.toString()} lineItem={lineItem} />;
  });

  return (
    <>
      <div className='p-8 w-full flex flex-col items-center my-8'>
        <h2 className='text-center text-2xl mb-4'>
        Checkout
        </h2>
        {lineItems}
        <ul id='totals' className='my-8 flex flex-col items-end w-full'>
          <li>
            <strong>Subtotal</strong> ${checkout.subtotalPrice}
          </li>
          <li>
            <strong>Taxes</strong> ${checkout.totalTax}
          </li>
          <li>
            <strong>Total</strong> ${checkout.totalPrice}
          </li>
        </ul>
        <button
          className='border border-black bg-blue-300 text-2xl p-2'
          onClick={handleCheckout} disabled={checkout.lineItems.length === 0}
        >
        Check out
        </button>
      </div>
    </>
  );
};

export default Cart;
