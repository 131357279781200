import React, { useContext } from 'react';
import StoreContext from '../../context/store-context.js';

const LineItem = props => {
  const { lineItem } = props;
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext);

  const variantImage = lineItem.variant.image ? (
    <img
      className="w-32"
      src={lineItem.variant.image.src}
      alt={`${lineItem.title} product shot`}
      height="60px"
    />
  ) : null;

  const handleRemove = () => {
    removeLineItem(client, checkout.id, lineItem.id);
  };

  return (
    <div className='flex justify-between border-black items-end border-dashed border-b w-full'>
      {variantImage}
    <div className="flex">
      <p> {lineItem.title}    <span className="ml-4">x{lineItem.quantity}</span>
      <button onClick={handleRemove} className="bg-red-700 text-blue-300 p-1 px-2 text-xs ml-4">X</button>
    </p>
    </div>
    </div>
  )
}

export default LineItem
